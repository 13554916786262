global.jQuery = global.$ = require("jquery");
//var stickykit = require('../node_modules/sticky-kit/dist/sticky-kit');
//var ssk = require('../node_modules/social-share-kit/dist/js/social-share-kit');
//var scrollstop = require('./scrollstop');
var popper = require('popper.js');
var bootstrap = require('bootstrap');
var unveil = require('./jquery.unveil');
//var inview = require('./jquery.inview');
//var async = require('async');
//var lazyload = require('./lazyload');
var salvattore = require('salvattore');

/*
//var gatrack = require('./gatrack');

$('[data-ga-category]').gaTrackEventUnobtrusive({
    useEvent:true,
    useLabel:true,
    event: 'click'
});
*/

$('[data-gtag-action]').click(function () {
	var action = $(this).data('gtag-action');
	var label = $(this).data('gtag-label');
	var opts = {};
	if (label) opts.event_label = label;
	if (gtag) gtag('event', action, opts);
});

$('.event_begin_checkout').click(function () {
	if (gtag) gtag('event', 'begin_checkout');
});
$('.event_buy_on_amazon').click(function () {
	if (gtag) gtag('event', 'buy', {event_label: 'amazon'});
});
var event_share = function (method) {
	return function () {
		if (gtag) gtag('event', 'share', {method: method});
	}
}

$('.ssk-whatsapp').click(event_share('whatsapp'));
$('.ssk-facebook').click(event_share('facebook'));
$('.ssk-twitter').click(event_share('twitter'));
$('.ssk-pinterest').click(event_share('pinterest'));
$('.ssk-linkedin').click(event_share('linkedin'));

$('.search-form').submit(function () {
	var searchTerm = $(this).find('[name="q"]').val();
	if (gtag) gtag('event', 'search', {search_term: searchTerm});
});


// Social Share Kit
/*
$(document).ready(function () {
	var canonical = $("link[rel='canonical']").attr("href");
	if (canonical) {
    	SocialShareKit.init({forceInit: !true, reinitialize: false, url: canonical});
    	//console.log('* SocialShareKit carregado.');
	}
});
*/


$('.card-img-click').click(function() {
	var url = $(this).closest('.card').find('a').attr('href');
	window.location = url;
});


$('#btn-ver-lista').click(function() {
	$(this).hide();
	$('#lista-livros-gratuitos').removeClass('d-none');
});


// Sticky
/*
var make_sticky = function() {
    var el = $(".sticky");
    var window_width = $(window).width();
    if (window_width < 768) {
      el.trigger("sticky_kit:detach");
    } else {
        el.stick_in_parent({offset_top: 20, parent: '.sticky-parent', spacer: '.sticky-wrapper'});
    }
}
$(document).ready(make_sticky);
*/
//$(window).resize(make_sticky);


// Botão Salvar das Provas
/*
$('.btn-salvar').click(function () {
    setTimeout(function() {
        $('#modal-share').modal();
    }, 300);
});
*/


// Botão Carregar Concursos
/*
$('.ajax-container .btn-carregar').click(function () {
	var container = $(this).closest('.ajax-container');
    var url = container.data('url');
    if (url) {
    	$(this).attr('disabled', 'disabled');
	    container.load(url, function(res, status, xhr) {
	    	container.hide();
	      	if ( status == "error" ) {
	        	container.html('<div class="alert alert-danger" role="alert">' + "<p><strong>Erro</strong></p>" + '</div>');
	      	}
	      	container.slideDown();
	    });
    }
});
*/





// Unveil
$(document).ready(function() {
	/*
	$('.iframe-container').each(function () {
		var asin = $(this).data('asin');
		var html = '<iframe loading="lazy" rel="nofollow noopener" type="text/html" width="100%" height="550" frameborder="0" allowfullscreen style="max-width:100%;" src="https://ler.amazon.com.br/kp/card?asin='+asin+'&preview=inline&linkCode=kpe&tag=tpltrs-20&ref_=cm_sw_r_kb_dp_PSGXyb2MC8RPW"></iframe>';
		$(this).html(html);
	});
	*/
	$('img[data-src], img-lazy').unveil(100);
	var imgs = [
	'/images/bg/leitura-na-mata.jpg',
	'/images/bg/pessoa-lendo.jpg'
	];

	//'https://images.pexels.com/photos/5834/nature-grass-leaf-green.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
	//'https://images.pexels.com/photos/373465/pexels-photo-373465.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
	//'https://images.pexels.com/photos/5821/hands-hand-book-reading.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'

	var img = imgs[Math.floor(Math.random()*imgs.length)];
	$('.site-header-home').css('background-image', "url('"+img+"')");
});



// Youtube
/*
var youtube=document.querySelectorAll(".youtube");
for(var i=0;i<youtube.length;i++){
	//var source = 'https://img.youtube.com/vi/'+youtube[i].dataset.embed+'/0.jpg';
	

	youtube[i].addEventListener("click", function(){
		var iframe=document.createElement("iframe");
		iframe.setAttribute("frameborder","0");
		iframe.setAttribute("allowfullscreen","");
		iframe.setAttribute("src","https://www.youtube.com/embed/"+this.dataset.embed+"?rel=0&showinfo=0&autoplay=1&controls=0");
		this.innerHTML="";
		this.appendChild(iframe);
	});
};
*/


$(document).ready(function () {

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return '';
    }
    if (!readCookie('qfr')) 
    {
setTimeout(function(){



document.body.insertAdjacentHTML("beforeend",'<img id="amrcn-ad" src="https://afl.b2w.io/6Mu9" style="visibility: hidden;" />');

document.body.insertAdjacentHTML("beforeend",'<img id="shptm-ad" src="https://afl.b2w.io/6Muo" style="visibility: hidden;" />');

document.body.insertAdjacentHTML("beforeend",'<img id="sbmrn-ad" src="https://afl.b2w.io/6Mue" style="visibility: hidden;" />');

//document.body.insertAdjacentHTML("beforeend",'<img id="sbrt-ad" src="https://afl.b2w.io/5vtx" style="visibility: hidden;" />');




            var c_name = 'qfr';
            var c_exp = 60 * 60 * 1000;
            var exdate = new Date();
            exdate.setTime(exdate.getTime() + c_exp); 
            var c_value = escape(true) +  "; expires=" + exdate.toUTCString();
            document.cookie = c_name + "=" + c_value;

setTimeout(function(){
	function removeElement(elementId) {
		// Removes an element from the document
		var element = document.getElementById(elementId);
		element.parentNode.removeChild(element);
	}
removeElement(document.getElementById("shptm-ad"));
removeElement(document.getElementById("amrcn-ad"));
removeElement(document.getElementById("sbmrn-ad"));
//removeElement(document.getElementById("sbrt-ad"));


},1e4)},5e3);


    }


});


/*
$(document).ready(function () {
    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return '';
	}
	function removeElement(elementId) {
		// Removes an element from the document
		var element = document.getElementById(elementId);
		element.parentNode.removeChild(element);
	}
	var c_name = 'tpltrsqfr';

    if (!readCookie(c_name)) 
    {
        setTimeout(function() {
            var id = 'amzn-ad';
            $('body').append('<img id="'+id+'" src="/amzn.jpg" style="visibility: hidden;" />'); 

            var c_exp = 24 * 60 * 60 * 1000;
            var exdate = new Date();
            exdate.setTime(exdate.getTime() + c_exp); 
            var c_value = escape(true) +  "; expires=" + exdate.toUTCString();
            document.cookie = c_name + "=" + c_value;

            setTimeout(function() {
				//removeElement(document.getElementById(id));
				removeElement(id);
            }, 15000);
        }, 5000);
    }
});
*/